@use "mixins" as *
.prematchRegionItem
  margin-bottom: var(--live_categoryItemMargin)

.region
  @include flex
  background: var(--live_categoryItemBg)
  height: 42px
  padding: 0 10px 0
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2)
  span
    font-size: 13px
    text-transform: uppercase
    &:not(.gameCount)
      @include bold
  &.opened
    color: #fff
    .arrow
      transform: rotate(0)

.gameCount
  margin-left: auto

.arrow
  width: 10px
  height: 5px
  background: var(--icon-arrow) no-repeat
  transform-origin: 45% 50%
  transform: rotate(-90deg)
  transition: all 0.2s
  margin: 0 -2px 0 8px
  min-width: 10px
