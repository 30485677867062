@use "mixins" as *
.gameItem
  min-width: 110px
  height: 170px
  width: 110px!important
  margin: 10px 5px 0
  @include flex
  position: relative

  > div
    width: 100%
    height: 100%
    position: relative
    overflow: hidden
    border-radius: 14px
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.4)
    background: var(--casino_gameItemBg)
    display: flex
    justify-content: center
    transition: all 0.3s, border 0s

    &.new
      &::before
        content: ""
        width: 100%
        height: 100%
        box-shadow: inset 0 0 0 2px rgba(255,255,255,.2)
        position: absolute
        border-radius: 14px

      &:after
        content: ""
        width: calc(100% - 4px)
        height: calc(100% - 4px)
        top: 2px
        left: 2px
        box-shadow: inset 0 0 0 3px #000
        position: absolute
        border-radius: 14px

    :global(.imageNext)
      width: 100%
      position: relative

      img
        width: 100%
        height: auto

    video
      width: 100%
      height: 100%
      object-fit: cover
      border-radius: 14px

  &:active > div
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.4)

.reelsModal
  z-index: 999999999
  &.fullHeight
    --headerHeight: 0
    --menuHeight: 0

  :global(.modal)
    width: 80%
    height: auto
    max-width: 420px
    max-height: 98%
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    background: var(--preload_bg)
    transition: all 0.3s
    border-radius: 14px
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.6)

    .reelsModalTitle
      color: #fff
      font-size: 14px
      @include bold
      @include flex
      justify-content: center
      position: absolute
      width: 100%
      z-index: 1
      padding: 6px
    & :global
      .casino-game-item
        margin: 0
        width: 100%!important
        height: auto
        > div
          box-shadow: none
          border-radius: 0
          background: unset
          video
            border-radius: 0
    .reelsModalContent
      position: relative

.reelsModalInfo
  position: absolute
  bottom: 0
  color: #fff
  width: 100%
  .reelsModalInfoDiv
    position: relative
    padding: 6px 0 6px 10px
    > div
      @include flex
      position: relative
      z-index: 10
      :global(.imageNext)
        border-radius: 6px
        overflow: hidden
        margin-right: 10px
        min-width: 40px
      > p
        display: flex
        justify-content: center
        flex-direction: column
        min-height: 40px
        @include bold
        flex: 1
        min-width: 1px
        > span:nth-child(1)
          text-transform: uppercase
          @include ellipsis
        > span:nth-child(2)
          font-size: 16px
          margin-top: 4px
          @include ellipsis
    > p
      margin-top: 10px
      line-height: 16px
      padding-right: 10px
      position: relative
      z-index: 10

.bluredBg
  position: absolute
  bottom: 0
  left: 0
  width: 100%
  height: 100%
  backdrop-filter: blur(3px)
  z-index: 2
  overflow: hidden

  > span
    width: 100%
    height: 100%
    display: block
    position: absolute
    top: 0
    left: 0
    opacity: .4
    background: rgb(23, 31, 44)

.reelsModalBtn
  width: 100%
  background: linear-gradient(180deg, #39982C 0%, #207015 100%)
  border-radius: 0
  height: 40px
  font-size: 14px
  color: #fff
  @include flex
  justify-content: center
  text-transform: uppercase
  @include bold

  &:active
    background: #308A24

.unstarred
  min-width: 48px
  height: 40px
  @include flex
  justify-content: center
  &:before
    content: ""
    background-color: rgba(255,255,255, 0.7)
    @include star(22px)
    transition: all 0.8s
    transform: rotate(0deg)
    transform-origin: center
    animation: unstarred 0.5s ease-in
    pointer-events: none

    @keyframes unstarred
      50%
        width: 28px
        height: 28px
        mask-size: 28px 28px
      100%
        width: 22px
        height: 22px
        mask-size: 22px 22px

  &.starred:before
    background-color: var(--star_activeBg)
    animation: starred 0.5s ease-in
    transform: rotate(145deg)

    @keyframes starred
      50%
        width: 28px
        height: 28px
        mask-size: 28px 28px
      100%
        width: 22px
        height: 22px
        mask-size: 22px 22px

.reelsModalInfoButtons
  position: absolute
  right: 0
  flex-direction: column
  background: rgba(33, 38, 44, 0.3)
  height: 120px
  top: 0
  margin-top: -120px

  > button
    min-width: 40px
    background: transparent
    border-radius: 0
    color: rgb(var(--primaryColor))
    height: 50%
    flex-direction: column
    padding: 0
    > span
      @include flex
      margin-bottom: 6px

    &:nth-child(1) svg
      animation: heartbeatBack .4s

    &:nth-child(2):active svg path
      fill: #1C85FF

    &.liked svg
      animation: heartbeat .5s

    &.liked svg path
      fill: #FF166A

    @keyframes heartbeat
      0%
        transform: scale( 1 )

      30%
        transform: scale( 1.25 )

      60%
        transform: scale( 1 )

      80%
        transform: scale( 1.1 )

      100%
        transform: scale( 1 )

    @keyframes heartbeatBack
      0%
        transform: scale( 1 )

      30%
        transform: scale( 1.25 )

      60%
        transform: scale( 1 )

      80%
        transform: scale( 1.1 )

      100%
        transform: scale( 1 )

.titleExpandedView
  position: absolute
  background: rgba(0,0,0,.2)
  min-height: 24px
  border-bottom-right-radius: 3px!important
  font-size: 14px
  left: 0
  padding: 4px 6px
  @include bold
  @include ellipsis
  max-width: 100%
